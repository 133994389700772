import React from "react";
import {
  CustomContainer,
  CustomTitle,
  CustomCard,
  CustomCardTitle,
  CustomCardText,
} from "./style";
import { Container, Grid } from "@mui/material";
import ButtonBlue from "../../btnWhiteBlue";

export default function Section3() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12}>
            <CustomTitle>Tire o máximo partido</CustomTitle>
          </Grid>
          <Grid container spacing={4} justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={6} lg={6}>
              <CustomCard>
                <CustomCardTitle>
                  Dê um upgrade à sua produtividade
                </CustomCardTitle>
                <CustomCardText>
                  Garanta uma melhoria de produtividade com a otimização de
                  todos os processos desde da cotação até ao destino final.
                  Evite ainda a entrada de dados duplicados e obtenha acesso a
                  formulários automáticos adaptáveis ao seu negócio.
                </CustomCardText>
              </CustomCard>
            </Grid>

            <Grid item xs={12} sm={10} md={6} lg={6}>
              <CustomCard>
                <CustomCardTitle>Ganhe poder de decisão</CustomCardTitle>
                <CustomCardText>
                  Ganhe um aliado na tomada de decisões. Crie uma única fonte da
                  verdade, oferecendo uma visão geral do seu negócio sem
                  necessitar de analisar cada dado manualmente. Além disso,
                  garanta a precisão da informação de todos os detalhes
                  importantes em tempo real.
                </CustomCardText>
              </CustomCard>
            </Grid>

            <Grid item xs={12} sm={10} md={6} lg={6}>
              <CustomCard>
                <CustomCardTitle>Fique nas nuvems</CustomCardTitle>
                <CustomCardText>
                  Não perca tempo com a gestão de TI, servidores e
                  infraestrutura. Esta é uma solução cloud com a qual pode
                  beneficiar de velocidade, segurança e escalabilidade tendo um
                  custo total de propriedade menor.
                </CustomCardText>
              </CustomCard>
            </Grid>

            <Grid item xs={12} sm={10} md={6} lg={6}>
              <CustomCard>
                <CustomCardTitle>Não pare no tempo</CustomCardTitle>
                <CustomCardText>
                  Não existem limites para o crescimento. Esta solução está em
                  constante evolução, possui ainda um conjunto completo de
                  módulos e integrações que se adaptam às suas necessidades em
                  constante mudança. Além disso, é possível adicionar aos
                  software as suas próprias extensões ou integrações
                  personalizadas.
                </CustomCardText>
              </CustomCard>
            </Grid>
          </Grid>
          <Grid item xs={12} marginTop={"64px"} align="center">
            <ButtonBlue
              bgcolor={"var(--dark)"}
              color={"var(--light)"}
              border={"1px solid var(--primary-blue)"}
              bgcolorhover={"var(--light)"}
              colorhover={"var(--primary-blue)"}
              borderhover={"1px solid var(--primary-blue)"}
            >
              Saber mais
            </ButtonBlue>
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
