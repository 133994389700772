import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomBox = styled(Box)`
  && {
    max-width: 60px;
    height: 100vh;
    box-shadow: 0px 3px 6px var(--shadow-second);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 64px 0;
    position: sticky;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }
`;

export const CustomTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 36px;
    transform: rotate(90deg);
  }
`;
