import { Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import ContactForm from "../contactform";
import {
  CustomText,
  CustomSubTitle,
  CustomTitle,
  CustomTextFunc,
  CustomButton,
  CustomLink,
} from "./style";
import ArrowLeft from "@mui/icons-material/KeyboardArrowLeftRounded";

export default function CardImageExpanded({ data, onClose }) {

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const functionalitiesArray = data.funcionalities
    .split(";")
    .map((item) => item.trim())
    .filter((item) => item !== "");


  return (
    <>
    <Card
      sx={{
        display: "flex",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        justifyContent: "center",
        alignItems: { xs: "flex-start", lg: "center" },
        zIndex: 1,
        backgroundColor: "var(--dark)",
        borderRadius: "0px",
        p: 0,
        m: 0,
        borderBottom: "1px solid var(--gray)",
      }}
     
    >
      <Grid container>
        <Grid item xs={12} md={12} lg={6} height={"100%"} position={"relative"}>
          <CardMedia
            component="img"
            image={data.image}
            alt={data.title}
            sx={{
              objectFit: "cover",
              height: { xs: "500px", lg: "100%" },
              width: "100%",
            }}
          />
          <CustomButton disableRipple onClick={onClose}>
            <ArrowLeft
              sx={{
                color: "var(--light)",
                p: 0,
                m: 0,
              }}
              
            />
          </CustomButton>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <CardContent
              sx={{
                flex: "1 0 auto",
                p: "32px",
                pb: "32px !important",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <CustomTitle component="div">{data.title}</CustomTitle>
              <CustomText component="div">{data.text}</CustomText>
              <CustomSubTitle component="div">{data.subtitle}</CustomSubTitle>
              {functionalitiesArray.map((item, index) => (
                <CustomTextFunc component="div" key={"ctf_" + index}>
                  {item}
                </CustomTextFunc>
              ))}
                <CustomLink onClick={handleOpen}>Saber mais</CustomLink>  
                {openModal && (
                  <ContactForm openModal={openModal} handleClose={handleClose} style="transitarios" />
                )}
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Card>

    </>
  );
}
