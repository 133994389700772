import { Typography } from "@mui/material";
import styled from "styled-components";

export const CustomTypographyTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

export const CustomTypographyDesc = styled(Typography)`
  && {
    font-weight: 400;
    font-family: Poppins Regular;
    font-size: 16px;
    margin-bottom: 6px;
    width: 100%;
  }
`;
