import React, { useState } from "react";

import {
  GridContainer1 as GridContainer1Logistica,
  GridContainer2 as GridContainer2Logistica,
  STypographyContactTitle as STypographyContactTitleLogistica,
  STypographyContactSubText as STypographyContactSubTextLogistica,
  STypographyContactText as STypographyContactTextLogistica,
  SInput as SInputLogistica,
  SLabel as SLabelLogistica,
  SSelect as SSelectLogistica,
  SCheckbox as SCheckboxLogistica,
  STypographyCheck as STypographyCheckLogistica,
  SButtonSend as SButtonSendLogistica,
  SContainerForm as SContainerFormLogistica,
} from "./styleLogistica/style.js";

import {
  GridContainer1 as GridContainer1Transitarios,
  GridContainer2 as GridContainer2Transitarios,
  STypographyContactTitle as STypographyContactTitleTransitarios,
  STypographyContactSubText as STypographyContactSubTextTransitarios,
  STypographyContactText as STypographyContactTextTransitarios,
  SInput as SInputTransitarios,
  SLabel as SLabelTransitarios,
  SSelect as SSelectTransitarios,
  SCheckbox as SCheckboxTransitarios,
  STypographyCheck as STypographyCheckTransitarios,
  SButtonSend as SButtonSendTransitarios,
  SContainerForm as SContainerFormTransitarios,
} from "./styleTransitarios/style2.js";

import { MenuItem, Grid, CircularProgress, Modal } from "@mui/material";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function ContactForm({ openModal, handleClose, style }) {
  const options = [
    "Demo do produto",
    "Mais informação",
    "Outros Planos",
    "Entrada em contacto",
  ];
  let GridContainer1, GridContainer2, STypographyContactTitle, STypographyContactSubText, STypographyContactText, SInput, SLabel, SSelect, SCheckbox, STypographyCheck, SButtonSend, SContainerForm;

    if (style === "logistica") {
       GridContainer1 = GridContainer1Logistica;
       GridContainer2 = GridContainer2Logistica;
       STypographyContactTitle = STypographyContactTitleLogistica;
       STypographyContactSubText = STypographyContactSubTextLogistica;
       STypographyContactText = STypographyContactTextLogistica;
       SInput = SInputLogistica;
       SLabel = SLabelLogistica;
       SSelect = SSelectLogistica;
       SCheckbox = SCheckboxLogistica;
       STypographyCheck = STypographyCheckLogistica;
       SButtonSend = SButtonSendLogistica;
       SContainerForm = SContainerFormLogistica;
      } else if (style === "transitarios") {
       GridContainer1 = GridContainer1Transitarios;
       GridContainer2 = GridContainer2Transitarios;
       STypographyContactTitle = STypographyContactTitleTransitarios;
       STypographyContactSubText = STypographyContactSubTextTransitarios;
       STypographyContactText = STypographyContactTextTransitarios;
       SInput = SInputTransitarios;
       SLabel = SLabelTransitarios;
       SSelect = SSelectTransitarios;
       SCheckbox = SCheckboxTransitarios;
       STypographyCheck = STypographyCheckTransitarios;
       SButtonSend = SButtonSendTransitarios;
       SContainerForm = SContainerFormTransitarios;
    };

  const [loading, setLoading] = useState(false);
  const [user_name, setUser_name] = useState("");
  const [user_empresa, setUser_empresa] = useState("");
  const [user_cargo, setUser_cargo] = useState("");
  const [user_mail, setUser_mail] = useState("");
  const [user_phone, setUser_phone] = useState("");
  const [user_produto, setUser_produto] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const data = {
      user_name,
      user_empresa,
      user_cargo,
      user_mail,
      user_phone,
      user_produto,
    };

    setLoading(true);

    try {
      emailjs
        .send("service_nul8gzv", "template_7zo1twq", data, "XPyM3pvsU6t-qzaRc")
        .then((result) => {
          // handleOpenMessage();
          setLoading(false);
        });
    } catch (error) {
      toast.error("Erro ao enviar o email!");
      setLoading(false);
    }
  };

  return (
    <Modal open={openModal} onClose={handleClose} id="modalContact">
      <SContainerForm maxWidth="lg">
        <Grid container sx={{ display: "flex", gap: "32px" }}>
          <GridContainer1 item xs={12} lg>
            <STypographyContactTitle>
              Abra uma porta para o futuro
            </STypographyContactTitle>
            <STypographyContactSubText>
              Saiba como pode melhorar o seu negócio com as nossas ferramentas
              de fácil integração, adaptáveis ao detalhe de cada negócio.
            </STypographyContactSubText>
            <STypographyContactText>
              Deixe-nos o seu contacto para que lhe enviemos mais informação ou
              para marcar uma apresentação do produto sem qualquer compromisso.
            </STypographyContactText>
          </GridContainer1>

          <GridContainer2 item xs={12} lg>
            <Grid item xs>
              <SLabel>Nome</SLabel>
              <SInput
                required
                disableUnderline={true}
                onChange={(e) => setUser_name(e.target.value)}
              />
            </Grid>

            <Grid container sx={{ display: "flex", gap: "21px" }}>
              <Grid item xs>
                <SLabel>Empresa</SLabel>
                <SInput
                  disableUnderline={true}
                  onChange={(e) => setUser_empresa(e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <SLabel>Cargo</SLabel>
                <SInput
                  disableUnderline={true}
                  onChange={(e) => setUser_cargo(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ display: "flex", gap: "21px" }}>
              <Grid item xs>
                <SLabel>Email</SLabel>
                <SInput
                  required
                  disableUnderline={true}
                  onChange={(e) => setUser_mail(e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <SLabel>Telemóvel</SLabel>
                <SInput
                  name="user_phone"
                  disableUnderline={true}
                  onChange={(e) => setUser_phone(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={4}>
                <SLabel>Pretendo obter</SLabel>
              </Grid>
              <Grid item xs={12} lg={8}>
                <SSelect
                  required
                  id="outlined-select-currency"
                  select="true"
                  sx={{
                    "& fieldset": { border: "none" },
                    "& .MuiSvgIcon-root": { color: "var(--blue)" },
                    "& .MuiSelect-select": {
                      color: "var(--blue)",
                      fontSize: "20px",
                      fontWeight: "300",
                      fontFamily: "Poppins Light",
                    },
                  }}
                  onChange={(e) => setUser_produto(e.target.value)}
                  defaultValue=""
                >
                  {options.map((item, index) => (
                    <MenuItem
                      sx={{
                        fontSize: "20px",
                        fontWeight: "300",
                        fontFamily: "Poppins Light",
                        "&:hover": {
                          color: "var(--blue)",
                        },
                        selected: {
                          fontSize: "20px",
                          fontWeight: "300",
                          fontFamily: "Poppins Light",
                        },
                      }}
                      key={index}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </SSelect>
              </Grid>
            </Grid>

            <Grid container sx={{ marginTop: "22px" }}>
              <Grid
                item
                xs={12}
                lg={8}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SCheckbox type="checkbox" />
                <STypographyCheck>
                  Li e aceito os{" "}
                  <a
                    href="/terms-conditions#terms"
                    target="_blank"
                    style={{
                      color: "var(--blue)",
                      textDecoration: "underline",
                    }}
                  >
                    termos de Privacidade
                  </a>
                </STypographyCheck>
              </Grid>
              <Grid item xs={12} lg={4} align="end">
                <SButtonSend type="submit" onClick={sendEmail}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "var(--white)",
                      }}
                    />
                  ) : (
                    "Enviar"
                  )}
                </SButtonSend>
              </Grid>
            </Grid>
          </GridContainer2>
        </Grid>
      </SContainerForm>
    </Modal>
  );
}
