import { Card, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomCard = styled(Card)`
  && {
    border-radius: 10px;
    background: var(--light);
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 395px;
    width: 100%;
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 20px;
    color: var(--dark);
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--dark);
  }
`;
