import React, { useState } from "react";
import { CustomCard, CustomTypography, CustomText, CustomTitle } from "./style";

export default function CardAppMobile({ children, title, text }) {
  const [showInfoBox, setShowInfoBox] = useState(false);

  const handleClick = () => {
    setShowInfoBox(!showInfoBox);
  };

  return (
    <CustomCard showInfoBox={showInfoBox} onClick={handleClick}>
      <CustomTypography>{children}</CustomTypography>
      <div className="triangle" />
      {showInfoBox && (
        <div className="info-box">
          <CustomTitle>{title}</CustomTitle>
          <CustomText>{text}</CustomText>
        </div>
      )}
    </CustomCard>
  );
}
