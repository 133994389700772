import { Card, CardMedia } from "@mui/material";
import React from "react";
import ArrowRight from "@mui/icons-material/KeyboardArrowRightRounded";
import { CustomButton } from "./style";
export default function CardImage({ image, onClick }) {
  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        borderRadius: "0px",
        opacity: "0.86",
        "&:hover": { opacity: "1" },
        cursor: "pointer",
        maxHeight: { xs: "500px", lg: "100%" },
      }}
      onClick={onClick}
    >
      <CardMedia component="img" image={image} />
      <CustomButton disableRipple>
        <ArrowRight
          sx={{
            color: "var(--light)",
            p: 0,
            m: 0,
          }}
        />
      </CustomButton>
    </Card>
  );
}
