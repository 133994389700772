import { Card, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomCard = styled(Card)`
  && {
    background: var(--dark);
    border-radius: 15px;
    padding: 32px;
    box-sizing: border-box;
    min-height: 593px;
    max-width: 395px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 145px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    @media (max-width: 768px) {
      max-width: 100%;
      min-height: 50px;
    }

    &:hover {
      .triangle {
        scale: 1;
      }
      .info-box {
        opacity: 1;
        transition: 0.3s ease-in-out;
      }
    }

    .triangle {
      width: 0;
      height: 0;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      border-left: 64px solid transparent;
      border-right: 0px solid transparent;
      border-bottom: 64px solid var(--primary);
      transition: width 0.3s ease, height 0.3s ease;
      z-index: 1;
    }

    .info-box {
      color: var(--light);
      padding: 32px;
      box-sizing: border-box;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.3s ease;
      z-index: 2;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: var(--primary);
    }
  }
`;
export const CustomTypography = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 26px;
    color: var(--primary);
    margin-top: 40px;
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 20px;
    color: var(--light);
    margin-bottom: 26px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--light);
  }
`;
