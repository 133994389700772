import styled from "styled-components";
import { Button } from "@mui/material";

export const CustomButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 64px;
    z-index: 4;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    border: 2px solid var(--dark);
    @media (max-width: 600px) {
      display: none;
    }
  }
`;
