import { Container, Typography } from "@mui/material";
import styled from "styled-components";
import BG from "../../../assets/images/TransitErrorbg.png";

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-size: 26px;
    font-family: Poppins Semibold;
    color: var(--light);
    max-width: 395px;
    margin-bottom: 32px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-size: 16px;
    font-family: Poppins Regular;
    color: var(--light);
    max-width: 395px;
    margin-bottom: 32px;
  }
`;
