import { Card, Container, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  && {
    padding: 185px 0px 200px 0px;
    background: var(--dark);
    @media (max-width: 768px) {
      padding: 128px 0px 128px 0px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-size: 36px;
    font-family: Poppins Regular;
    color: var(--primary-blue);
    margin-bottom: 89px;
    text-align: center;
  }
`;

export const CustomCardTitle = styled(Typography)`
  && {
    font-size: 20px;
    font-family: Poppins Medium;
    color: var(--primary-blue);
  }
`;

export const CustomCardText = styled(Typography)`
  && {
    font-size: 16px;
    font-family: Poppins Regular;
    color: var(--gray-2);
  }
`;

export const CustomCard = styled(Card)`
  && {
    padding: 32px;
    background: var(--dark);
    box-sizing: border-box;
    border: 2px solid var(--primary-blue);
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: auto;
    max-height: 100%;
    position: relative;
    overflow: hidden;
    transition: 0.3s ease;
    @media (max-width: 768px) {
      border: 2px solid var(--light);
      ${CustomCardTitle} {
        color: var(--light);
      }
      ${CustomCardText} {
        color: var(--light);
      }
      box-shadow: 0px 0px 6px var(--light);
    }
    @media (min-width: 960px) {
      height: 320px;
    }
    @media (min-width: 1280px) {
      height: 290px;
    }
    &:hover {
      border: 2px solid var(--light);
      box-shadow: 0px 0px 6px var(--light);
      ${CustomCardTitle} {
        color: var(--light);
      }
      ${CustomCardText} {
        color: var(--light);
      }
    }
  }
`;
