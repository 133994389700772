import React from "react";
import { CustomContainer, CustomTitle, CustomSubTitle } from "./style";
import { Container, Grid } from "@mui/material";
import Reservas from "../../../assets/images/Reservas.svg";
import Declaracao from "../../../assets/images/balance-scale.svg";
import Stocks from "../../../assets/images/box-seam.svg";
import Cobranca from "../../../assets/images/invoice.svg";
import Manifesto from "../../../assets/images/Manifesto.svg";
import Embarque from "../../../assets/images/invoice.svg";
import CardProcess from "../../CardProcess";
import BtnWhite from "../../btnWhite";

export default function Section4() {
  const data = [
    {
      image: Reservas,
      title: "Reservas",
      text: "Aceite e gere reservas online, gerencie instantaneamente pedidos e entidades.",
    },
    {
      image: Declaracao,
      title: "Declaração Alfandegária",
      text: "Integração com a alfândega.",
    },
    {
      image: Stocks,
      title: "Gestão de Stocks",
      text: "Garanta que o seu stock está atualizado e obtenha relatórios a qualquer altura.",
    },
    {
      image: Cobranca,
      title: "Cobrança",
      text: "Faturação, recebimento de pagamentos, relatórios.",
    },
    {
      image: Manifesto,
      title: "Manifesto",
      text: "Envie manifestos automaticamente para os seus clientes ou exporte-os para PDF.",
    },
    {
      image: Embarque,
      title: "Conhecimento de Embarque",
      text: "Envie automaticamente o rascunho do BL para os seus clientes.",
    },
  ];

  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg" align="center">
        <CustomTitle>Trate de toda a documentação num só local</CustomTitle>
        <CustomSubTitle>
          Seja mais preciso e reduza o tempo desperdiçado automatizando grande
          parte dos processos.
        </CustomSubTitle>
        <Grid
          container
          spacing={4}
          display={"flex"}
          justifyContent={"center"}
          mb={"95px"}
        >
          {data.map((item, index) => (
            <Grid item xs={12} sm={10} md={6} lg={4} key={index}>
              <CardProcess
                image={item.image}
                title={item.title}
                text={item.text}
              />
            </Grid>
          ))}
        </Grid>
        <BtnWhite>Saber mais</BtnWhite>
      </Container>
    </CustomContainer>
  );
}
