import styled from "styled-components";
import { Button } from "@mui/material";

export const BtnWhiteBlue = styled(Button)`
  && {
    border-radius: 10px;
    padding: 14px 32px;
    box-sizing: border-box;
    font-family: Poppins Regular;
    font-size: 20px;
    max-width: max-content;
    text-transform: none;
    height: 56px;
  }
`;
