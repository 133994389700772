import React from "react";
import {
  CustomContainer,
  CustomBox,
  CustomTitle,
  CustomText,
  CustomContainerChild,
} from "./style";
import { Container, useTheme } from "@mui/material";
import ButtonBlue from "../../btnWhiteBlue";

export default function Section2() {
  const theme = useTheme();

  return (
    <CustomContainer maxWidth="false" disableGutters>
      <CustomContainerChild maxWidth="lg">
        <CustomBox>
          <CustomTitle>
            Uma solução que cobre todas as áreas necessárias do negócio
          </CustomTitle>
          <CustomText>
            Troque dados em tempo real e gerencie todas as suas operações
            logísticas num único sistema, desde a criação de cotação de frete
            até o preenchimento da documentação alfandegária necessária,
            realizando procedimentos de gestão de armazém e logística de última
            milha. Evite entradas duplas de dados criando reservas, ordens de
            recolha, recibos de armazém, remessas, faturas, diretamente num
            sistema.
          </CustomText>
          <ButtonBlue
            bgcolor={"var(--light)"}
            color={"var(--primary-blue)"}
            border={"1px solid transparent"}
            bgcolorhover={"var(--primary-blue)"}
            colorhover={"var(--light)"}
            borderhover={"1px solid var(--primary-blue)"}
          >
            Saber mais
          </ButtonBlue>
        </CustomBox>
      </CustomContainerChild>
    </CustomContainer>
  );
}
