import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.svg";
import LogoAzul from "../../assets/images/LogoAzul.svg";
import { CustomContainer, CustomButton } from "./styles";
import Arrow from "../../assets/images/ArrowLeft.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

export default function Header() {
  const navigate = useNavigate();

  const handleChangePage = () => {
    if (window.location.pathname === "/") {
      navigate("/logistica");
    } else {
      navigate("/");
    }
  };

  const isLogisticRoute = useLocation().pathname === "/logistica";
  const [useBlueLogo, setUseBlueLogo] = useState(false);

  useEffect(() => {
    const updateLogo = () => {
      const shouldUseBlueLogo = window.innerWidth < 599 && isLogisticRoute;
      setUseBlueLogo(shouldUseBlueLogo);
    };
    window.addEventListener("resize", updateLogo);
    updateLogo();
    return () => window.removeEventListener("resize", updateLogo);
  }, [isLogisticRoute]);

  return (
    <CustomContainer maxWidth="lg" isLogisticRoute={isLogisticRoute}>
      <Box>
        <img src={useBlueLogo ? LogoAzul : Logo} alt="" />
      </Box>
      <CustomButton
        disableRipple
        onClick={handleChangePage}
        isLogisticRoute={isLogisticRoute}
      >
        {isLogisticRoute ? "Transitários" : "Logística"}
        <img
          style={{
            transform: isLogisticRoute ? "rotate(0deg)" : "rotate(180deg)",
          }}
          src={Arrow}
          alt=""
        />
      </CustomButton>
    </CustomContainer>
  );
}
