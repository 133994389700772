import styled from "styled-components";
import { Button } from "@mui/material";

export const BtnOrange = styled(Button)`
  && {
    background: var(--primary);
    box-shadow: 0px 3px 6px var(--primary);
    border-radius: 10px;
    padding: 14px 32px;
    box-sizing: border-box;
    font-family: Poppins Regular;
    font-size: 20px;
    max-width: max-content;
    border: 1px solid var(--primary);
    color: var(--light);
    text-transform: none;
    height: 56px;
    &:hover {
      border: 1px solid var(--primary);
      background: var(--dark);
      color: var(--primary);
    }
  }
`;
