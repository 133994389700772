import { Button, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 36px;
    color: var(--primary);
    text-shadow: 0px 1px 6px var(--shadow-third);
    margin-bottom: 32px;
    @media (max-width: 1500px) {
      font-size: 26px;
      margin-bottom: 16px;
    }
  }
`;

export const CustomSubTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 20px;
    color: var(--light);
    margin-bottom: 32px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--light);
    margin-bottom: 64px;
    max-width: 609px;
    @media (max-width: 1500px) {
      font-size: 16px;
      margin-bottom: 32px;
    }
  }
`;

export const CustomTextFunc = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--light);
    max-width: 545px;
    margin-bottom: 6px;
  }
`;

export const CustomButton = styled(Button)`
  && {
    background: var(--dark);
    border-radius: 50%;
    min-width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 36px;
    &:hover {
      background: var(--dark);
    }
  }
`;

export const CustomLink = styled.a`
  && {
    font-family: Poppins Medium;
    margin-top: 32px;
    font-size: 20px;
    color: var(--primary);
    text-align: left;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    max-width: max-content;
    &::after{
        content: "";
        position: absolute;
        bottom:0;
        left:0;
        width: 0;
        height: 2px;
        background-color: var(--primary);
        transition: width 0.3s ease-in-out;
    }
    &:hover::after{
        width: 100%;
  }
`;
