import React from "react";
import { Container } from "@mui/material";
import Header from "../../../layouts/Header";
import { useState } from "react";
import ContactForm from "../../contactform";
import {
  CustomTypography,
  CustomBox,
  CustomTypographyBox,
  CustomTypographyText,
  CustomLink,
  CustomImg,
} from "./style";
import Airplane from "../../../assets/images/bgwrapper.png";

export default function Home() {

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        background: "var(--primary)",
        position: "relative",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Header />
        <CustomTypography>Transitários</CustomTypography>
        <CustomBox>
          <CustomTypographyBox>
            Faça uma gestão mais eficiente em tempo-real
          </CustomTypographyBox>
          <CustomTypographyText>
            Uma solução que se foca em otimizar todos os processos transitários.
            Desde de reservas online, Tracking, integração com transportadoras,
            gestão de taxas e faturação automática e muito mais.
          </CustomTypographyText>
          <CustomLink onClick={handleOpen}>Peça uma Demo</CustomLink>
          {openModal && (
            <ContactForm openModal={openModal} handleClose={handleClose} style="transitarios" />
          )}
        </CustomBox>
      </Container>
      <CustomImg src={Airplane} alt="airplane" />
    </Container>
  );
}
