import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import data from "../../data/footer.json";
import { CustomTypographyTitle, CustomTypographyDesc } from "./styles";
import AdditionalInfo from "./components/AdditionalInfo";
import Facebook from "../../assets/images/facebook.svg";
import FacebookWhite from "../../assets/images/facebookWhite.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import LinkedinWhite from "../../assets/images/linkedinWhite.svg";
import Certificacoes from "../../assets/images/certifs.svg";
import CertificacoesWhite from "../../assets/images/certifsWhite.svg";

const iconMapping = {
  facebook: Facebook,
  linkedin: Linkedin,
  facebookWhite: FacebookWhite,
  linkedinWhite: LinkedinWhite,
};

const imageMapping = {
  "/": Certificacoes,
  "/logistica": CertificacoesWhite,
};

export default function Footer({
  bgColor,
  titleColor,
  textColor,
  bgBottomColor,
}) {
  const location = useLocation();
  const isLogisticaRoute = location.pathname === "/logistica";

  const imageToShow = imageMapping[location.pathname] || Certificacoes;
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: bgColor,
          padding: { xs: "64px 0px", lg: "160px 0px" },
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ gap: { xs: "32px", lg: "0px" } }}
          >
            {Object.entries(data).map(([title, items]) => (
              <Grid item xs={12} sm={6} md={2.5} key={title}>
                <CustomTypographyTitle sx={{ color: titleColor }}>
                  {title}
                </CustomTypographyTitle>
                {Array.isArray(items)
                  ? items
                      .filter(
                        (item) =>
                          !(
                            isLogisticaRoute &&
                            (item.icon === "facebook" ||
                              item.icon === "linkedin")
                          ) &&
                          !(
                            location.pathname === "/" &&
                            (item.icon === "facebookWhite" ||
                              item.icon === "linkedinWhite")
                          )
                      )
                      .map((item) => (
                        <Link
                          to={item.link}
                          key={item.text}
                          style={{ textDecoration: "none", color: textColor }}
                          target="_blank"
                        >
                          {item.icon === "facebook" ||
                          item.icon === "facebookWhite" ||
                          item.icon === "linkedinWhite" ||
                          item.icon === "linkedin" ? (
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                width: "fit-content",
                                gap: "16px",
                              }}
                            >
                              <img
                                src={iconMapping[item.icon]}
                                alt={item.icon}
                                style={{
                                  padding: "0px",
                                  width: "max-content",
                                  height: "max-content",
                                }}
                              />
                              <CustomTypographyDesc key={item}>
                                {item.text}
                              </CustomTypographyDesc>
                            </div>
                          ) : item.text ===
                            "(chamada para rede fixa nacional)" ? (
                            <CustomTypographyDesc
                              key={item}
                              style={{ fontSize: "9px", cursor: "default" }}
                            >
                              {item.text}
                            </CustomTypographyDesc>
                          ) : item.image === "certificacoes" ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                                flexDirection: "column",
                                alignItems: "start",
                                cursor: "default",
                              }}
                            >
                              <img
                                src={imageToShow}
                                style={{ maxWidth: "100%", height: "auto" }}
                                alt=""
                              />
                              <CustomTypographyDesc
                                key={item}
                                style={{ fontSize: "9px", marginTop: "5px" }}
                              >
                                {item.text}
                              </CustomTypographyDesc>
                            </Box>
                          ) : (
                            <CustomTypographyDesc key={item} fontWeight="bold">
                              {item.text === "Googlemaps" ? (
                                <strong>{item.text}</strong>
                              ) : (
                                item.text
                              )}
                            </CustomTypographyDesc>
                          )}
                        </Link>
                      ))
                  : ""}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <AdditionalInfo bgBottomColor={bgBottomColor} />
    </>
  );
}
