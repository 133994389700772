import { Typography } from "@mui/material";
import styled from "styled-components";

export const CustomTypography = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 36px;
    color: var(--dark);
    text-align: center;
    margin-bottom: 64px;
  }
`;

export const CustomTypography2 = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 36px;
    color: var(--primary);
    text-align: center;
    margin-bottom: 26px;
  }
`;
