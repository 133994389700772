import { Container, Grid } from "@mui/material";
import React from "react";
import CardImage from "../../CardImage";
import Aviao from "../../../assets/images/aviao.png";
import Navio from "../../../assets/images/navio.png";
import Camiao from "../../../assets/images/camiao.png";
import { useState } from "react";
import CardImageExpanded from "../../CardImageExpanded";
import data from "../../../data/CardImages";

export default function Section5() {
  const [expanded, setExpanded] = useState(null);

  const handleCardClick = (index) => {
    setExpanded(index);
  };

  return (
    <Container maxWidth="false" disableGutters>
      <Grid container gap={"4px"} position={"relative"}>
        <Grid item xs={12} lg>
          <CardImage image={Aviao} onClick={() => handleCardClick(0)} />
        </Grid>
        <Grid item xs={12} lg>
          <CardImage image={Navio} onClick={() => handleCardClick(1)} />
        </Grid>
        <Grid item xs={12} lg>
          <CardImage image={Camiao} onClick={() => handleCardClick(2)} />
        </Grid>
        {expanded !== null && (
          <CardImageExpanded
            data={data[expanded]}
            onClose={() => setExpanded(null)}
          />
        )}
      </Grid>
    </Container>
  );
}
