import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Logistica from "../pages/Logistica";
import Error from "../pages/Error/Transit_Error";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/logistica" element={<Logistica />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default AppRoutes;
