import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 100px;
    color: var(--light);
    text-align: left;
    max-width: max-content;
    z-index: 1;
    text-shadow: 0px 1px 3px #0000005c;
    @media (max-width: 768px) {
      font-size: 48px;
    }
  }
`;

export const CustomTypographyBox = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 26px;
    color: var(--light);
    text-align: left;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

export const CustomTypographyText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--dark);
    text-align: left;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export const CustomBox = styled(Box)`
  && {
    max-width: 609px;
    background-color: var(--light);
    box-shadow: 0px 3px 6px var(--shadow);
    padding: 32px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 64px;
    margin-bottom: 32px;
    z-index: 1;
    @media (max-width: 768px) {
      margin-top: 32px;
      padding: 32px 16px;
      max-width: 100%;
    }
  }
`;

export const CustomImg = styled.img`
  && {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    z-index: 0;
  }
`;
