import { Button, Container } from "@mui/material";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  && {
    position: absolute;
    top: 16px;
    left: 0;
    color: var(--dark);
    z-index: 3;
    @media (max-width: 599px) {
      position: fixed;
      top: 0px;
      z-index: 3;
      background-color: ${({ isLogisticRoute }) =>
        isLogisticRoute ? "white" : "var(--dark)"};
      padding: 16px;
      box-sizing: border-box;
      width: 100%;
      box-shadow: 0px 3px 6px var(--shadow-second);
      display: flex;
      flex-direction: ${({ isLogisticRoute }) =>
        isLogisticRoute ? "row-reverse" : "row"};
      justify-content: space-between;
      max-height: 60px;
    }
  }
`;

export const CustomButton = styled(Button)`
  && {
    color: ${({ isLogisticRoute }) =>
      isLogisticRoute ? "var(--dark)" : "var(--light)"};
    border: none;
    font-family: Poppins Medium;
    font-size: 20px;
    text-transform: none;
    display: none;
    cursor: pointer;
    @media (max-width: 599px) {
      display: flex;
      flex-direction: ${({ isLogisticRoute }) =>
        isLogisticRoute ? "row-reverse" : "row"};
      align-items: center;
      gap: 8px;
    }
  }
`;
