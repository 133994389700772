import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomBox = styled(Box)`
  && {
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    padding: 64px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: end;
    @media (max-width: 768px) {
      padding: 64px 16px;
      width: 100%;
    }
  }
`;

export const CustomBox2 = styled(Box)`
  && {
    border-radius: 5px 0px 0px 5px;
    padding: 64px 0px;
    padding-left: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      padding: 64px 16px;
    }
    @media (max-width: 1250px) {
      gap: 32px;
    }
  }
`;

export const CustomTypographyBox = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 26px;
    text-align: left;
    color: var(--primary);
    max-width: 609px;
  }
`;

export const CustomTypographyText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    text-align: left;
    color: var(--light);
  }
`;

export const CustomTypographyBoxWhite = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 26px;
    text-align: left;
    color: var(--light);
    max-width: 502px;
  }
`;
