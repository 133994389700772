import React from "react";
import {
  CustomContainer,
  CustomGrid,
  CustomText,
  CustomTitle,
  CustomGrid2,
  CustomInput,
  CustomLabel,
  CustomBtnSend,
  CustomCheckText,
} from "./style";
import { Box, Checkbox, Grid } from "@mui/material";
export default function Section5() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Grid container maxWidth="lg" gap={0}>
        <CustomGrid item xs={12} sm={12} md={6} lg={6}>
          <CustomTitle>Abra uma porta para o futuro</CustomTitle>
          <CustomText>
            Saiba como pode melhorar o seu negócio com as nossas ferramentas de
            fácil integração e adaptáveis ao detalhe de cada negócio.
          </CustomText>
        </CustomGrid>
        <CustomGrid2 item xs={12} sm={12} md={6} lg={6}>
          <form style={{ width: "100%" }}>
            <Grid container display={"flex"} sx={{ marginBottom: "16px" }}>
              <Grid item xs={12}>
                <CustomLabel>Nome*</CustomLabel>
              </Grid>
              <Grid item xs={12}>
                <CustomInput type="text" required disableUnderline />
              </Grid>
            </Grid>
            <Box
              sx={{ marginBottom: "16px", display: { xs: "grid", md: "flex" } }}
              gap={"32px"}
              width={"100%"}
            >
              <Grid container display={"grid"}>
                <Grid item xs={12} lg={12}>
                  <CustomLabel>Empresa</CustomLabel>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInput type="text" disableUnderline />
                </Grid>
              </Grid>
              <Grid container display={"grid"}>
                <Grid item xs={12} lg={12}>
                  <CustomLabel>Cargo</CustomLabel>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInput type="text" disableUnderline />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{ marginBottom: "32px", display: { xs: "grid", md: "flex" } }}
              gap={"32px"}
              width={"100%"}
            >
              <Grid container display={"grid"}>
                <Grid item xs={12} lg={12}>
                  <CustomLabel>Email*</CustomLabel>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInput type="email" required disableUnderline />
                </Grid>
              </Grid>
              <Grid container display={"grid"}>
                <Grid item xs={12} lg={12}>
                  <CustomLabel>Telemóvel</CustomLabel>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInput
                    inputProps={{
                      maxLength: 9,
                      pattern: "/9[1236][0-9]{7}|2[1-9][0-9]{7}/",
                    }}
                    type="tel"
                    disableUnderline
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              width={"100%"}
              height={"max-content"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <CustomBtnSend type="submit">Enviar</CustomBtnSend>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"100%"}
              >
                <Checkbox></Checkbox>
                <CustomCheckText>
                  Li e aceito os termos de Privacidade
                </CustomCheckText>
              </Box>
            </Box>
          </form>
        </CustomGrid2>
      </Grid>
    </CustomContainer>
  );
}
