import React from "react";
import { BtnOrange } from "./style";
import { useState } from "react";
import ContactForm from "../contactform";




export default function ButtonOrange({ children }) {

  const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => {
      setOpenModal(true);
    };

    const handleClose = () => {
      setOpenModal(false);
    };
  return(
    <>

      <BtnOrange onClick={handleOpen}>{children}</BtnOrange>
      {openModal && (
        <ContactForm openModal={openModal} handleClose={handleClose} style="transitarios" />
      )}
    </>
  );
}
