import "./assets/css/App.css";
import Routes from "./routes/routes.js";
import ScrollTopButton from "./components/ScrollToTopButton";

function App() {
  return (
    <>
      <Routes />
      <ScrollTopButton />
    </>
  );
}

export default App;
