import React, { useEffect, useState } from "react";
import { CustomButton } from "./style";
import ArrowUp from "@mui/icons-material/ArrowUpwardRounded";
import { useLocation } from "react-router-dom";

export default function ScrollTopButton() {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const isLogisticaRoute = location.pathname === "/logistica";

  return isVisible ? (
    <CustomButton
      onClick={scrollToTop}
      disableRipple
      style={{
        [isLogisticaRoute ? "right" : "left"]: "12px",
        border: isLogisticaRoute
          ? "2px solid var(--primary-blue)"
          : "2px solid var(--dark)",
      }}
    >
      <ArrowUp
        sx={{
          color: isLogisticaRoute ? "var(--primary-blue)" : "var(--dark)",
        }}
      />
    </CustomButton>
  ) : null;
}
