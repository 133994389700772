import React, { useEffect, useState } from "react";
import { CustomCard, CustomTypography, CustomTitle, CustomText } from "./style";
import CardAppMobile from "../CardAppMobile";

export default function CardApp({ children, image, text, title }) {
  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  return (
    <>
      {isMobile ? (
        <CardAppMobile title={title} text={text}>
          {children}
        </CardAppMobile>
      ) : (
        <CustomCard>
          <img src={image} alt="" />
          <CustomTypography>{children}</CustomTypography>
          <div className="triangle" />
          <div className="info-box">
            <CustomTitle>{title}</CustomTitle>
            <CustomText>{text}</CustomText>
          </div>
        </CustomCard>
      )}
    </>
  );
}
