import { Box, Container, Grid } from "@mui/material";
import React from "react";
import {
  CustomBox,
  CustomBox2,
  CustomTypographyBox,
  CustomTypographyBoxWhite,
  CustomTypographyText,
} from "./style";
import BtnOrange from "../../btnOrange";
import BtnWhite from "../../btnWhite";

export default function Section2() {
  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        marginTop: { xs: "0px", md: "2px" },
      }}
    >
      <Grid container maxWidth="false" gap={"2px"}>
        <Grid item xs={12} lg={7} sx={{ background: "var(--dark)" }}>
          <CustomBox>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                pr: { xs: "0px", md: "145px" },
                pl: { xs: "0px", lg: "calc(100% - 750px)" },
              }}
            >
              <CustomTypographyBox>
                Tracking de mercadorias com registo que pode ser integrado em
                qualquer website
              </CustomTypographyBox>
              <CustomTypographyText>
                Se ainda não tiver site, preparamos um para si.
              </CustomTypographyText>
              <BtnOrange>Saber mais</BtnOrange>
            </Box>
          </CustomBox>
        </Grid>
        <Grid item xs={12} lg sx={{ background: "var(--primary)" }}>
          <CustomBox2>
            <CustomTypographyBoxWhite>
              Potencia as vendas com a gestão comercial e mediação de clientes
            </CustomTypographyBoxWhite>
            <BtnWhite>Saber mais</BtnWhite>
          </CustomBox2>
        </Grid>
      </Grid>
    </Container>
  );
}
