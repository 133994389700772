import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { CustomTypography, CustomTypography2 } from "./style";
import CardApp from "../../CardApp";
import Anchor from "../../../assets/images/anchor.svg";
import Truck from "../../../assets/images/truck.svg";
import ContainerLine from "../../../assets/images/container-line.svg";
import BtnOrange from "../../btnOrange";

export default function Section3() {
  const data = [
    {
      image: Truck,
      title: "Transitários",
      text: "Incorpora um módulo de E-Freight e a integração e conferência de CASS. Permite efetuar a gestão de processos (terrestres, marítimos e aéreos), ordens de carga e documentos, cálculos de rentabilidade de processos, gestão de armazém geral, de exportação e de importação (depósito temporário e entreposto aduaneiro), faturação automática, tracking and tracing de mercadorias, gestão comercial e mediação com clientes e a integração com outros sistemas de informação da cadeia de negócio com mensagens standard. Pode ainda importar e exportar todos os dados para formatos comuns (Excel, Word, PDF...).",
    },
    {
      image: ContainerLine,
      title: "Parque de Contentores",
      text: "Permite controlar a movimentação de contentores, otimizando os processos de entrada, a movimentação e saída nos parques de contentores e adquirir e guardar informação de localização geográfica que permite conhecer em tempo real a localização de cada contentor armazenado e dos meios de movimentação.",
    },
    {
      image: Anchor,
      title: "Agentes de Navegação",
      text: "Permite gerir todas as atividades na área comercial, documentação (emissão de manifestos de carga e emissão de BL's), importação, exportação, operacional e financeira. Possibilita a integração com parceiro de negócio, como armadores, terminais, parques, ou outras entidades como portos e Alfândega. Solução que integra com a JUP e com a JUL de forma a permitir a emissão de manifesto eletrónico de mercadorias através de XML.",
    },
  ];

  return (
    <Container maxWidth="false" disableGutters>
      <Container
        maxWidth="lg"
        sx={{ paddingTop: "135px", paddingBottom: "170px" }}
      >
        <CustomTypography>Aplicação Modular</CustomTypography>
        <Grid container marginBottom={"64px"} spacing={{ xs: 2, sm: 4 }}>
          {data.map((item, index) => (
            <Grid item xs={12} md={6} lg key={index}>
              <CardApp image={item.image} title={item.title} text={item.text}>
                {item.title}
              </CardApp>
            </Grid>
          ))}
        </Grid>
        <CustomTypography2>Conheça todos os nossos módulos</CustomTypography2>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <BtnOrange>Descobre já!</BtnOrange>
        </Box>
      </Container>
    </Container>
  );
}
