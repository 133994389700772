import Person from "../assets/images/person.svg";
import Phone from "../assets/images/phone.svg";
import Speed from "../assets/images/speed.svg";
import Operations from "../assets/images/operations.svg";

const data2 = [
  {
    image: Operations,
    text: "Digitalize as operações e elimine silos, garantindo dados precisos e holísticos para todos em uma única plataforma.",
  },
  {
    image: Speed,
    text: "Simplifique e acelere drasticamente o processo de solicitação de cotação, além de consultar e gerenciar taxas rápidas e precisas num único ponto de controlo.",
  },
  {
    image: Phone,
    text: "Mantenha os clientes informados sobre a localização da sua carga com um aplicativo móvel e notificações automatizadas, reduzindo chamadas e e-mails.",
  },
  {
    image: Person,
    text: "Elimine a entrada manual e duplicada de dados, protegendo os processos contra erros humanos e recuperando horas do seu dia, permitindo que você assuma mais negócios!",
  },
];

export default data2;
