import React from "react";
import BtnChangePage from "../../components/BtnChangePage";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Section1 from "../../components/LogisticaSection/Section1";
import Section2 from "../../components/LogisticaSection/Section2";
import Section3 from "../../components/LogisticaSection/Section3";
import Section4 from "../../components/LogisticaSection/Section4";
import Section5 from "../../components/LogisticaSection/Section5";
import Footer from "../../layouts/Footer";

export default function Logistica() {
  return (
    <>
      <Box display={"flex"} width={"100%"}>
        <Box height={"auto"} display={{ xs: "none", sm: "block" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <BtnChangePage
              rotate={"rotate(-90deg)"}
              rotateIcon={"rotate(180deg)"}
              textColor={"var(--dark)"}
              bgColor={"var(--light)"}
            >
              Transitários
            </BtnChangePage>
          </Link>
        </Box>
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Footer
            bgColor={"var(--dark)"}
            titleColor={"var(--primary-blue)"}
            textColor={"var(--light)"}
            bgBottomColor={"var(--primary-blue)"}
          />
        </Box>
      </Box>
    </>
  );
}
