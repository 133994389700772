import { Button } from "@mui/material";
import styled from "styled-components";

export const CustomButton = styled(Button)`
  && {
    background: var(--dark);
    border-radius: 50%;
    min-width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 36px;
    &:hover{
      background: var(--dark);
    }
  }
`;
