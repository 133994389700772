import React from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import { Container, Grid } from "@mui/material";
import { CustomText, CustomTitle, CustomContainer } from "./style";
import ButtonWhite from "../../../components/btnWhite";
import BgError from "../../../assets/images/bgError.svg";
import { Link } from "react-router-dom";

export default function Transit_Error() {
  return (
    <>
      <CustomContainer maxWidth="false" disableGutters>
        <Container maxWidth="lg" sx={{ position: "relative" }}>
          <Grid
            container
            height={"100vh"}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid item>
              <Header />
              <CustomTitle>A página solicitada não foi encontrada.</CustomTitle>
              <CustomText>
                Isso pode acontecer devido aos seguintes motivos:
                <br />
                <br /> A página ou o arquivo está desatualizado, renomeado,
                movido ou não existe.
                <br />
                <br /> Você digitou o endereço incorretamente, como
                http://www.example.com/pgae.html em vez de
                http://www.example.com/page.html
              </CustomText>
              <Link to="/" style={{ textDecoration: "none" }}>
                <ButtonWhite>Voltar</ButtonWhite>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </CustomContainer>
      <Footer />
    </>
  );
}
