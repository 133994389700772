import React from "react";
import { Container, Grid } from "@mui/material";
import { STypography, STypographyDesc } from "./styles";

export default function AdditionalInfo({bgBottomColor}) {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ background: bgBottomColor }}
    >
      <Container maxWidth="lg" sx={{ height: "100%" }}>
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} md={3} lg={1}>
            <STypography>Legal</STypography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            sx={{ textAlign: { lg: "center" } }}
          >
            <a href="/cookies" style={{ textDecoration: "none" }}>
              <STypographyDesc>Política de Cookies</STypographyDesc>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            sx={{ textAlign: { lg: "center" } }}
          >
            <a href="/privacy" style={{ textDecoration: "none" }}>
              <STypographyDesc>Política de Privacidade</STypographyDesc>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            sx={{ textAlign: { lg: "center" } }}
          >
            <a href="/terms-conditions" style={{ textDecoration: "none" }}>
              <STypographyDesc>Termos e Condições</STypographyDesc>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            sx={{ textAlign: { lg: "center" } }}
          >
            <a
              href="https://www.livroreclamacoes.pt/Inicio/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <STypographyDesc>Livro de Reclamações</STypographyDesc>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            sx={{ textAlign: { lg: "end" } }}
          >
            <STypographyDesc>
              ©2023 JCanão. All rights reserved.
            </STypographyDesc>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
