import { Card, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomCard = styled(Card)`
  && {
    background: var(--dark);
    border-radius: 10px;
    padding: 16px 16px;
    box-sizing: border-box;
    min-height: 56px;
    height: ${({ showInfoBox }) => (showInfoBox ? "auto" : "56px")};
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    ${({ showInfoBox }) =>
      showInfoBox &&
      `
      flex-direction: column;
      width: 100%;
      background: var(--primary);
    `}

    .info-box {
      display: ${({ showInfoBox }) => (showInfoBox ? "block" : "none")};
      width: 100%;
      background: var(--primary);
    }

    .triangle {
      width: 0;
      height: 0;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      border-left: 54px solid transparent;
      border-right: 0px solid transparent;
      border-bottom: 54px solid var(--primary);
      transition: width 0.3s ease, height 0.3s ease;
      z-index: 1;
    }
  }
`;
export const CustomTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 20px;
    color: var(--primary);
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 20px;
    color: var(--light);
    margin-bottom: 26px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--light);
  }
`;
