import React from "react";
import Section1 from "../../components/TransitariosSection/Section1";
import Section2 from "../../components/TransitariosSection/Section2";
import Section3 from "../../components/TransitariosSection/Section3";
import Section4 from "../../components/TransitariosSection/Section4";
import Section5 from "../../components/TransitariosSection/Section5";
import Section6 from "../../components/TransitariosSection/Section6";
import Section7 from "../../components/TransitariosSection/Section7";
import Footer from "../../layouts/Footer";
import BtnChangePage from "../../components/BtnChangePage";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Box display={"flex"} width={"100%"}>
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
          <Section7 />
          <Footer
            bgColor={"var(--light-2)"}
            titleColor={"var(--primary)"}
            textColor={"var(--dark)"}
            bgBottomColor={"var(--dark)"}
          />
        </Box>
        <Box height={"auto"} display={{ xs: "none", sm: "block" }}>
          <Link to="/logistica" style={{ textDecoration: "none" }}>
            <BtnChangePage textColor={"var(--light-2)"} bgColor={"var(--dark)"}>
              Logística
            </BtnChangePage>
          </Link>
        </Box>
      </Box>
    </>
  );
}
