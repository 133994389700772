import React from "react";
import { CustomCard, CustomText, CustomTitle } from "./style";

export default function CardProcess({ image, title, text }) {
  return (
    <CustomCard>
      <img src={image} alt="" />
      <CustomTitle>{title}</CustomTitle>
      <CustomText>{text}</CustomText>
    </CustomCard>
  );
}
