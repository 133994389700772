import React from "react";
import { BtnWhiteBlue } from "./style";
import { useState } from "react";


import ContactForm from "../contactform";



export default function BtnBlue({
  children,
  bgcolor,
  color,
  border,
  borderhover,
  bgcolorhover,
  colorhover,
}) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <BtnWhiteBlue
        sx={{
          background: bgcolor,
          color: color,
          border: border,
          "&:hover": {
            border: borderhover,
            background: bgcolorhover,
            color: colorhover,
          },
        }}
        onClick={handleOpen}
      >
        {children}
      </BtnWhiteBlue>
      {openModal && (
        <ContactForm openModal={openModal} handleClose={handleClose} style="logistica" />
      )}
    </>
  );
}
