import React from "react";
import { CustomBox, CustomTypography } from "./style";
import ArrowLeft from "../../assets/images/ArrowLeft.svg";

export default function BtnChangePage({
  bgColor,
  textColor,
  children,
  rotate,
  rotateIcon,
}) {
  return (
    <CustomBox sx={{ background: `${bgColor}` }}>
      <img style={{ transform: rotateIcon }} src={ArrowLeft} alt="arrow-left" />
      <CustomTypography style={{ color: textColor, transform: rotate }}>
        {children}
      </CustomTypography>
      <img style={{ transform: rotateIcon }} src={ArrowLeft} alt="arrow-left" />
    </CustomBox>
  );
}
