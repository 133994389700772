import React from "react";
import { Container } from "@mui/material";
import Header from "../../../layouts/Header";
import {
  CustomTypography,
  CustomBox,
  CustomTypographyText,
  CustomImg,
} from "./style";
import image from "../../../assets/images/log_top.svg";
import ButtonBlue from "../../../components/btnWhiteBlue";

export default function Home() {
  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        background: "var(--primary-blue)",
        position: "relative",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Header />
        <CustomTypography>Logística</CustomTypography>
        <CustomBox>
          <CustomTypographyText>
            Solução desenvolvida especificamente para empresas de transporte de
            mercadorias e logística que contém tudo o que possa necessitar para
            gerir todo o negócio, interna ou externamente. Permite maximizar o
            uso de recursos e prever as mudanças do mercado de forma a reduzir
            riscos.
          </CustomTypographyText>
        </CustomBox>
        <ButtonBlue
          bgcolor={"var(--light)"}
          color={"var(--primary-blue)"}
          border={"1px solid transparent"}
          bgcolorhover={"var(--dark)"}
          colorhover={"var(--light)"}
          borderhover={"1px solid var(--light)"}
        >
          Saber mais
        </ButtonBlue>
      </Container>
      <CustomImg src={image} alt="image1" />
    </Container>
  );
}
