import styled from "styled-components";
import BG from "../../../assets/images/sec4bg.svg";
import { Container, Typography } from "@mui/material";

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 395px;
    padding-bottom: 295px;
    @media (max-width: 768px) {
      padding-top: 163px;
      padding-bottom: 176px;
      background-color: var(--primary);
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 36px;
    color: var(--light);
    text-align: center;
    margin-bottom: 26px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

export const CustomSubTitle = styled(Typography)`
  && {
    max-width: 607px;
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--light);
    text-align: center;
    margin-bottom: 64px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;
