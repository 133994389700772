import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import BG from "../../../assets/images/sec2bg.png";

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;
    position: relative;

    ::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 0;
      border-right: 100vw solid var(--dark);
      border-top: 340px solid transparent;
      @media (max-width: 1200px) {
        border-top: 240px solid transparent;
      }
      @media (max-width: 768px) {
        border-top: 100px solid transparent;
      }
    }
  }
`;

export const CustomContainerChild = styled(Container)`
  && {
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      padding: 0px;
    }
  }
`;

export const CustomBox = styled(Box)`
  && {
    background: linear-gradient(#39c5c5, #d0f1f1, #ffffff00);
    border-radius: 0px 0px 15px 15px;
    padding: 243px 64px 570px 64px;
    box-sizing: border-box;
    max-width: 737px;
    max-height: 100%;
    @media (max-width: 768px) {
      max-width: 100%;
      padding: 16px 16px 128px 16px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 26px;
    color: var(--light);
    margin-bottom: 32px;
    max-width: 609px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--dark);
    margin-bottom: 64px;
    max-width: 609px;
  }
`;
