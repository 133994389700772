import Aviao from "../assets/images/aviao.png";
import Navio from "../assets/images/navio.png";
import Camiao from "../assets/images/camiao.png";

const data = [
  {
    image: Aviao,
    title: "Carga Aérea",
    subtitle: "Funcionalidades",
    text: "Permite efetuar toda a documentação oficial do processo de exportação e importação aérea, bem como a troca eletrónica de informação/documentos entre os vários intervenientes. O arquivo eletrónico de toda a documentação emitida para o processo e de outros documentos é efetuado automaticamente",
    funcionalities: `
    - Gestão de Processos e Houses;
    - Emissão de documentos oficiais: Carta de Porte, Manifesto de Carga e Pré-Alert;
    - Gestão automática de stocks de Carta de Porte;
    - Registo de serviços adicionais e custos provisionais por Processo;
    - Envio de Documentos via e-mail;
    - Controlo de não conformidades do Processo;`,
  },
  {
    image: Navio,
    title: "Carga Marítima",
    subtitle: "Funcionalidades",
    text: "Permite efetuar toda a documentação oficial do processo de exportação e importação marítima, bem como a troca eletrónica de informação/ documentos entre os vários intervenientes. O arquivo eletrónico de toda a documentação emitida para o processo e de outros documentos é efetuado automaticamente",
    funcionalities: `
    - Gestão de Processos e Ordens de Carga;
    - Emissão de documentos oficiais: TBL, DME, Marcação Despacho, Declaração de Exportação, Pré-Alert;
    - Elaborar Planos de Carga de Contentores;
    - Tracking de Contentores;
    - Emissão de FCR;
    - Registo de serviços adicionais e custos provisionais por Processo;
    - Envio de Documentos via e-mail;
    - Controlo de não conformidades do Processo;
    - Análise de processos e Distribuição de Custos;
    `,
  },
  {
    image: Camiao,
    title: "Carga Terrestre",
    subtitle: "Funcionalidades",
    text: "Permite efetuar toda a documentação oficial do processo de exportação e importação terrestre, bem como a troca eletrónica de informação/ documentos entre os vários intervenientes. O arquivo eletrónicode toda a documentação emitida para o processo e de outros documentos é efetuado automaticamente.",
    funcionalities: `
    - Gestão de Processos e Ordens de Carga;
    - Emissão de documentos oficiais como: FCR, DME, Marcação Despacho, Pré-Alert;
    - Registo de serviços adicionais e custos provisionais por Processo;
    - Envio de Documentos via e-mail;
    - Controlo de não conformidades do Processo;
    - Análise de processos e Distribuição de Custos;
    `,
  },
];

export default data;
