import styled from "styled-components";
import { Box, Card, Container, Typography } from "@mui/material";
import BG from "../../../assets/images/bgsection4.png";

export const CustomContainer = styled(Container)`
  && {
    padding: 173px 0px 210px 0px;
    box-sizing: border-box;
    height: auto;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${BG});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 0.56;
      z-index: -1;
    }
    @media (max-width: 768px) {
      padding: 128px 0px;
    }
  }
`;

export const CustomCard = styled(Card)`
  && {
    border-radius: 10px;
    padding: 32px;
    box-sizing: border-box;
    background: ${(props) => props.bgColor};
    box-shadow: 0px 3px 6px #00000029;
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 26px;
    color: var(--light);
    margin-bottom: 26px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--light);
  }
`;

export const CustomText2 = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 18px;
    color: var(--dark);
  }
`;

export const CustomBox = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }
`;

export const CustomModuleTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 36px;
    color: var(--primary-blue);
    margin-bottom: 64px;
    position: relative;
    max-width: max-content;
    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: var(--primary-blue);
      box-shadow: 0px 3px 6px var(--primary-blue);
    }
  }
`;

export const CustomModuleCardTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 20px;
    color: var(--primary-blue);
    margin-bottom: 32px;
  }
`;

export const CustomModuleCardText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--dark);
  }
`;
