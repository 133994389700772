import { Button, Container, Grid, Input, Typography } from "@mui/material";
import styled from "styled-components";
import Grid1 from "../../../assets/images/blue.svg";
import Grid2 from "../../../assets/images/white.svg";

export const CustomContainer = styled(Container)`
  && {
    padding-top: 160px;
    padding-bottom: 232px;
    background: var(--dark);
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }
`;

export const CustomGrid = styled(Grid)`
  && {
    background: url(${Grid1}) no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 155px 0px;
    box-sizing: border-box;
    @media (max-width: 960px) {
      background-size: cover;
      background: var(--primary-blue);
      padding: 128px 16px;
      box-sizing: border-box;
      align-items: start;
    }
  }
`;
export const CustomGrid2 = styled(Grid)`
  && {
    background: url(${Grid2}) no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 155px 32px;
    box-sizing: border-box;
    @media (max-width: 960px) {
      display: block;
      background: var(--light);
      padding: 128px 16px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 26px;
    color: var(--light);
    margin-bottom: 32px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--light);
    max-width: 395px;
  }
`;

export const CustomInput = styled(Input)`
  && {
    width: 100%;
    height: 36px;
    min-height: 36px;
    background: var(--light-2);
    border-radius: 5px;
    font-family: Poppins Regular;
    padding: 0px 8px;
    box-sizing: border-box;
    "& .muioutlinedinput-notchedoutline": {
      border: none;
    }
  }
`;

export const CustomLabel = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--primary-blue);
    margin-bottom: 8px;
  }
`;

export const CustomBtnSend = styled(Button)`
  && {
    background: var(--primary-blue);
    color: var(--light);
    border-radius: 10px;
    font-family: Poppins Regular;
    font-size: 20px;
    padding: 9px 28px;
    box-sizing: border-box;
    text-transform: none;
    position: relative;
    height: 56px 45px;
    &:hover {
      background: var(--dark);
    }
  }
`;

export const CustomCheckText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--dark);
  }
`;
