import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  && {
    padding-top: 200px;
    padding-bottom: 345px;
    background: var(--dark);
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    ::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      border-left: 1920px solid transparent;
      border-right: 0px solid transparent;
      border-bottom: 350px solid var(--light-2);
    }
    @media (max-width: 768px) {
      padding-top: 128px;
      padding-bottom: 159px;
      ::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        border-left: 768px solid transparent;
        border-right: 0px solid transparent;
        border-bottom: 120px solid var(--light-2);
      }
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 26px;
    color: var(--primary);
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

export const CustomSubTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 20px;
    color: var(--light);
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;
export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--light);
    margin-bottom: 64px;
  }
`;
