import React from "react";
import { BtnWhite } from "./style";
import { useState } from "react";
import ContactForm from "../contactform";

export default function ButtonWhite({ children }) {

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return(
  <>
    <BtnWhite onClick={handleOpen}>{children}</BtnWhite>
    {openModal && (
      <ContactForm openModal={openModal} handleClose={handleClose} style="transitarios" />
    )} 
  </>

  ); 
}
