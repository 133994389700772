import React from "react";
import {
  CustomContainer,
  CustomCard,
  CustomTitle,
  CustomText,
  CustomText2,
  CustomBox,
  CustomModuleTitle,
  CustomModuleCardTitle,
  CustomModuleCardText,
} from "./style";
import { Container, Grid, List, ListItem } from "@mui/material";
import Data from "../../../data/logistica.json";
import Data2 from "../../../data/CardLogistica";
import Button from "../../btnWhiteBlue";
import ModuleData from "../../../data/Modules.json";
import ButtonBlue from "../../btnWhiteBlue";

export default function Section4() {
  const { data } = Data;

  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          justifyContent={"center"}
          sx={{
            boxSizing: "border-box",
            marginBottom: { xs: "64px", lg: "337px" },
          }}
        >
          <Grid item xs={12} sm={10} md={6} lg={5}>
            <CustomCard bgColor={"var(--dark)"}>
              <CustomTitle>Lógistica e Aprovisionamento</CustomTitle>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  pt: 0,
                  pb: 0,
                  marginBottom: "54px",
                  "& .MuiListItem-root": {
                    display: "list-item",
                    p: 0,
                    mb: 1,
                    color: "var(--light)",
                  },
                }}
              >
                {data.map((item, index) => (
                  <ListItem key={index}>
                    <CustomText>{item}</CustomText>
                  </ListItem>
                ))}
              </List>
              <Button
                bgcolor={"var(--dark)"}
                border={"1px solid var(--light)"}
                color={"var(--light)"}
                bgcolorhover={"var(--light)"}
                colorhover={"var(--dark)"}
                borderhover={"1px solid transparent"}
              >
                Saber mais
              </Button>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={10} md={6} lg={7}>
            <CustomCard
              elevation={0}
              bgColor={"var(--light)"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {Data2.map((item, index) => (
                <CustomBox key={index}>
                  <Grid
                    container
                    sx={{ marginBottom: { xs: "32px", md: "0" } }}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{ marginBottom: { xs: "12px", md: "0" } }}
                    >
                      <img src={item.image} alt="" key={index} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={9}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <CustomText2>{item.text}</CustomText2>
                    </Grid>
                  </Grid>
                </CustomBox>
              ))}
            </CustomCard>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          marginBottom={"64px"}
          justifyContent={"center"}
        >
          <Grid item xs={12} align="center">
            <CustomModuleTitle>Módulos</CustomModuleTitle>
          </Grid>
          {ModuleData.map((item, index) => (
            <Grid item xs={12} sm={10} md={6} lg key={index}>
              <CustomCard
                sx={{ height: { xs: "auto", lg: "354px" } }}
                bgColor={"var(--light)"}
              >
                <CustomModuleCardTitle>{item.title}</CustomModuleCardTitle>
                <CustomModuleCardText>{item.text}</CustomModuleCardText>
              </CustomCard>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} align="center">
          <ButtonBlue
            bgcolor={"var(--primary-blue)"}
            color={"var(--light)"}
            border={"1px solid transparent"}
            bgcolorhover={"var(--light)"}
            colorhover={"var(--primary-blue)"}
            borderhover={"1px solid var(--primary-blue)"}
          >
            Saber mais
          </ButtonBlue>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
