import React from "react";
import {
  CustomContainer,
  CustomText,
  CustomSubTitle,
  CustomTitle,
} from "./style";
import { Box } from "@mui/material";
import ButtonOrange from "../../btnOrange";

export default function Section6() {
  return (
    <CustomContainer maxWidth="false" disableGutter>
      <Box display={"flex"} flexDirection={"column"} maxWidth={"673px"}>
        <CustomTitle>
          É mais fácil do que pensa transformar as suas operações logísticas
        </CustomTitle>
        <CustomSubTitle>
          Não só é fácil, como é necessário para combater a concorrência.
        </CustomSubTitle>
        <CustomText>
          Automatize processos repetitivos e tediosos de forma a garantir uma
          redução de erros, tempo e custos. Retire maior proveito dos seus
          recursos e certifique-se que satisfaz os seus clientes. Tudo isto numa
          só solução.
        </CustomText>
        <ButtonOrange>Saber mais</ButtonOrange>
      </Box>
    </CustomContainer>
  );
}
